import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import {
    BUILDING_HEATING_COOLING_GO_TO_SPACE,
    BUILDING_THRESHOLD_BREACH_EXPAND_GRAPH,
    BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED,
} from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { Resolution } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import { getLinkToSpace } from '../../spaces/space/spaceFunctions';
import humanizedTimeString from './buildingInsightCommonFunctions';
import generateDetailsConfig from './BuildingInsightDetailsGraphConfig';
import TrendOverTimeDeviceGraph from './TrendOverTimeDeviceGraph';

dayjs.extend(timezone);

type StateProps = {
    dateFormat: keyof typeof dateFormats;
};
type ParentProps = {
    selectedPoint?: string;
    insight: BuildingInsight;
    resolution: Resolution;
    timeZone?: string;
    selectedHighThreshold: { id: string; inputValue: string };
    selectedLowThreshold?: { id: string; inputValue: string };
    detailsExpanded: boolean;
    setDetailsExpanded: (expanded: boolean) => void;
};
export type Props = ParentProps & StateProps;

type PointType = {
    serialNumber: string;
    name: string;
    overThresholdsTime: number;
    underThresholdsTime: number;
};

export const InsightBreakDownComponent = ({
    selectedPoint,
    dateFormat,
    timeZone,
    resolution,
    selectedHighThreshold,
    selectedLowThreshold,
    detailsExpanded,
    insight,
    setDetailsExpanded,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState<string | undefined>();
    const {
        sensor,
        unit,
        trendData: { aggregatedDevices, devicesInTimeFrame },
    } = insight;
    const selectedPointProps = aggregatedDevices.find(data => data.date === selectedPoint);
    const outsideThresholdTime =
        selectedPointProps && selectedPointProps.overThresholdsTime + selectedPointProps.underThresholdsTime;
    const devicesInPoint: PointType[] = selectedPoint
        ? devicesInTimeFrame[selectedPoint].devices.sort((device1, device2) => {
              if (
                  device1.overThresholdsTime + device1.underThresholdsTime >
                  device2.overThresholdsTime + device2.underThresholdsTime
              )
                  return -1;
              return 1;
          })
        : [];

    const expandDetails = (expanded: boolean): void => {
        if (detailsExpanded !== expanded) {
            setDetailsExpanded(expanded);
            analyticsLogger(BUILDING_THRESHOLD_BREACH_EXPAND_GRAPH, {
                pageType: PageType.Building,
                payload: { detailsExpanded: expanded },
            });
        }
    };

    useEffect(() => {
        setSelectedDevice(undefined);
        expandDetails(false);
    }, [selectedPoint]);

    const onDetailsClicked = (clickData: PointerEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const selectedSerialNumber = clickData.point.category;
        if (selectedDevice === selectedSerialNumber) {
            setSelectedDevice(undefined);
            expandDetails(false);
        } else {
            analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_BAR_SELECTED, {
                pageType: PageType.Building,
                barType: 'device',
            });
            setSelectedDevice(selectedSerialNumber);
        }
    };
    const detailsData = devicesInPoint.map(device => device.overThresholdsTime);
    const lowerLimitChartData = devicesInPoint.map(device => device.underThresholdsTime);
    const categories = devicesInPoint ? devicesInPoint.map(device => device.serialNumber) : [];
    const graphDetailsConfig = generateDetailsConfig(
        detailsData,
        lowerLimitChartData,
        categories,
        devicesInPoint,
        onDetailsClicked,
        200,
        txt,
        false,
        '',
        false,
        true,
        `graphDetails${selectedPoint}`
    );

    const selectedDeviceData = devicesInPoint && devicesInPoint.find(device => device.serialNumber === selectedDevice);
    const timeOutsideRange = (time?: number): string => {
        if (time) {
            return humanizedTimeString(time);
        }
        return `-${txt('TimeUnit.HoursShort')} -${txt('TimeUnit.Minutes')}`;
    };

    const devicesWithKeyInfo = useSelector((state: Store) => state.devices.devicesWithKeyInfo);

    const linkToSpace = (serialNumber?: string): string => {
        const deviceInfo = serialNumber && devicesWithKeyInfo[serialNumber];
        if (deviceInfo && deviceInfo.spaceId) {
            analyticsLogger(BUILDING_HEATING_COOLING_GO_TO_SPACE, {
                locationId: deviceInfo.locationId,
                spaceId: deviceInfo.spaceId,
                serialNumber,
            });
            return getLinkToSpace(deviceInfo.spaceId, deviceInfo.locationId);
        }
        return '';
    };

    return (
        <div
            className={classNames(
                'building-insight__card__section',
                'building-insight__card__section--breakdown',
                'building-insight__card__section--relative',
                {
                    'building-insight__card__section--expanded': detailsExpanded,
                    'building-insight__card__section--display-expand': !!selectedDevice,
                }
            )}
        >
            {selectedDevice && (
                <button
                    className="building-insight__card__section--relative__expand-button"
                    type="button"
                    onClick={(): void => expandDetails(!detailsExpanded)}
                >
                    <MaterialIcon name={detailsExpanded ? 'expand_more' : 'expand_less'} />
                </button>
            )}
            <div
                className={classNames(
                    'building-insight__card__section--content',
                    'building-insight__card__section--content--breakdown',
                    { 'building-insight__card__section--content--breakdown--expanded': detailsExpanded }
                )}
            >
                <div className="building-insight__card__section--breakdown__graph">
                    <HighchartsReact highcharts={Highcharts} options={graphDetailsConfig} />
                </div>
                {selectedPoint ? (
                    <div className="building-insight__card__section--breakdown__info">
                        <div className="text-medium text-bold">
                            {timeZone && moment.utc(selectedPoint).tz(timeZone).format('dddd')}{' '}
                            {timeZone &&
                                moment
                                    .utc(selectedPoint)
                                    .tz(timeZone)
                                    .format(dateFormats[dateFormat].calendarFormat)}{' '}
                            {resolution === Resolution.hour && timeZone && (
                                <span>
                                    {dayjs.tz(selectedPoint, timeZone).format(dateFormats[dateFormat].hourFormat)}-
                                    {dayjs
                                        .tz(selectedPoint, timeZone)
                                        .add(1, 'hour')
                                        .format(dateFormats[dateFormat].hourFormat)}
                                </span>
                            )}
                        </div>
                        <div className="text-medium">
                            {txt('BuildingInsight.TotalTimeOutsideBoundary')}
                            {timeOutsideRange(outsideThresholdTime)}
                        </div>
                        {selectedDeviceData && (
                            <>
                                <div className="text-medium text-bold small-padding-top">
                                    <Link to={linkToSpace(selectedDevice)} target="_blank" rel="noopener noreferrer">
                                        {selectedDeviceData.name}
                                    </Link>
                                </div>
                                <div className="text-medium">
                                    {selectedDeviceData.overThresholdsTime > 0 &&
                                        selectedDeviceData.underThresholdsTime > 0 && (
                                            <div>
                                                {txt('BuildingInsight.Over')} {selectedHighThreshold.inputValue}:
                                            </div>
                                        )}
                                    {selectedDeviceData.overThresholdsTime > 0 &&
                                        timeOutsideRange(selectedDeviceData.overThresholdsTime)}
                                    {selectedLowThreshold &&
                                        selectedDeviceData.overThresholdsTime > 0 &&
                                        selectedDeviceData.underThresholdsTime > 0 && (
                                            <div className="small-padding-top">
                                                {txt('BuildingInsight.Under')} {selectedLowThreshold.inputValue}:
                                            </div>
                                        )}
                                    {selectedDeviceData.underThresholdsTime > 0 &&
                                        timeOutsideRange(selectedDeviceData.underThresholdsTime)}
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="building-insight__card__section--breakdown__info">
                        <div className="text-medium text-bold">{txt('BuildingInsight.SelectDate')}</div>
                    </div>
                )}
            </div>
            {selectedDevice && selectedPoint && detailsExpanded && (
                <TrendOverTimeDeviceGraph
                    selectedPoint={selectedPoint}
                    serialNumber={selectedDevice}
                    sensorType={sensor}
                    unit={unit}
                    selectedHighThreshold={selectedHighThreshold}
                    selectedLowThreshold={selectedLowThreshold}
                    resolution={resolution}
                    timeZone={timeZone}
                />
            )}
        </div>
    );
};

export default InsightBreakDownComponent;
