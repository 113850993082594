import moment from 'moment';
import DeviceType from './components/DeviceType';
import { graphColorSensors } from './DeviceAndSensorLists';
import { DeviceTypeNames, RequiredRoleLevel, Resolution, SensorTypes, VirtualDeviceType } from './models/commonEnums';
import cssColors from './style/variables/colors.scss';

export const A4BSupportMail = 'business-support@airthings.com';
export const ConsumerSupportMail = 'support@airthings.com';
export const dashboardUri = 'https://dashboard.airthings.com';
export const publicDeviceUrl =
    'https://now.airthin.gs/{{publicUrlPath}}?lang={{languageCode}}&unit={{measurementSystem}}';
export const publicDeviceShortUrl = 'now.airthin.gs/{{publicUrlPath}}';
export const publicDashboardUrl =
    'https://now.airthin.gs/dashboard/{{publicUrlPath}}?lang={{languageCode}}&unit={{measurementSystem}}';
export const dashboardClientId = 'dashboard';
export const ACCOUNTS_URL_DEV =
    'https://accounts.{{environment}}.airthings.com/authorize?client_id={{clientId}}&redirect_uri={{redirectUri}}';
export const ACCOUNTS_URL_PROD = `https://accounts.airthings.com/authorize?client_id=${dashboardClientId}&redirect_uri=${dashboardUri}`;
export const ACCOUNTS_LOGOUT_URL_PROD = `https://accounts.airthings.com/login?client_id=${dashboardClientId}&redirect_uri=${dashboardUri}`;
export const ACCOUNTS_LOGOUT_URL_DEV =
    'https://accounts.{{environment}}.airthings.com/login?client_id={{clientId}}&redirect_uri={{redirectUri}}';
export const ACCOUNTS_REGISTRATION_URL_PROD = `https://accounts.airthings.com/registration?client_id=${dashboardClientId}&redirect_uri=${dashboardUri}`; // eslint-disable-line
export const ACCOUNTS_REGISTRATION_URL_DEV =
    'https://accounts.{{environment}}.airthings.com/registration?' +
    'client_id={{clientId}}&redirect_uri={{redirectUri}}';

export const OLD_API_URL_DEV = 'https://web-api.{{environment}}.airthin.gs/v1';
export const OLD_API_URL_PROD = 'https://web-api.airthin.gs/v1';

export const API_URL_DEV = 'https://dashboard-api.{{environment}}.airthin.gs/v1';
export const API_URL_PROD = 'https://dashboard-api.airthin.gs/v1';
export const PUBLIC_API_URL_DEV = 'https://dashboard-public-api.{{environment}}.airthin.gs/v1';
export const PUBLIC_API_URL_PROD = 'https://dashboard-public-api.airthin.gs/v1';

export const ACCOUNTS_API_URL_DEV = 'https://accounts-api.{{environment}}.airthings.com/v1';
export const ACCOUNTS_API_URL_PROD = 'https://accounts-api.airthings.com/v1';

export const BACKOFFICE_URL_DEV = 'https://backoffice.{{environment}}.airthings.com';
export const BACKOFFICE_URL_PROD = 'https://backoffice.airthings.com';

export const THIRD_PARTY_REDIRECT_CALLBACK_URL_DEV =
    'http://localhost:3000/integrations/third-party/add-third-party-integration';
export const THIRD_PARTY_REDIRECT_CALLBACK_URL_PROD =
    'https://dashboard.airthings.com/integrations/third-party/add-third-party-integration';

export const GOOGLE_ISSUER_URL = 'https://accounts.google.com';
export const MICROSOFT_ISSUER_URL = 'https://login.microsoftonline.com/organizations/v2.0';
export const ACCOUNTS_REDIRECT_URI_DEV = 'https://accounts.{{environment}}.airthings.com/federated';
export const ACCOUNTS_REDIRECT_URI_PROD = 'https://accounts.airthings.com/federated';
export const linkToAirthingsGithub = 'https://github.com/airthings';
export const linkToAirthingsAfBReadme = 'https://developer-business.airthings.com/';
export const shopLink = 'https://airthings.com/products';
export const cookieLink = 'https://airthings.com/cookie-policy';
export const privacyNoticeLink = 'https://airthings.com/privacy-notice';
export const supplementalPrivacyNoticeLink = 'https://www.airthings.com/supplemental-privacy-notice';
export const linkToTermsOfUse = 'https://www.airthings.com/terms-of-use';
export const hbsGettingStartedGuide = 'https://airthings.com/manuals';
export const howToMeasureRadonLink = 'https://www.airthings.com/resources/radon-levels';
export const B2ChubSpotEndPoint =
    'https://api.hsforms.com/submissions/v3/integration/submit/4406702/e3d9640b-02f8-482c-9deb-ddd08b7057de';
export const B2BhubSpotEndPoint =
    'https://api.hsforms.com/submissions/v3/integration/submit/4406702/cc600c72-f90e-44df-a09f-2f69f0f257af';
export const hubSpotNewsletterSubscriptionTypeId = '5874835';
export const EMAIL_REGEX = /^[\w.+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
// eslint-disable-next-line security/detect-unsafe-regex
export const DOMAIN_REGEX = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])+$/;
// eslint-disable-next-line security/detect-unsafe-regex
export const URL_REGEX = /^https:\/\/([\w-]+\.)+[a-zA-Z]{2,}(\/[\w\-./]*)*$/;
export const PASSWORD_SPECIALS_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
export const ORGANIZATION_NAME_REGEX = /^.{2,}$/;
export const pollingDelayMs = 5 * 60 * 1000;
export const drawGraphTime = 1200;
export const INTERCOM_APP_ID = 'zkz0xktu';
export const INTERCOM_B2B_APP_ID = 'm1tgbu8v';
export const linkToWebhooksDocs = 'https://developer-business.airthings.com/docs/webhooks-getting-started';
export const linkToApiDocs = 'https://developer-business.airthings.com/docs/api-getting-started';
export const linkToConsumerApiReference = 'https://consumer-api-doc.airthings.com/docs/api/getting-started';
export const linkToMqttDocs = 'https://developer-business.airthings.com/docs/mqtt-introduction';
export const helpArticleAfBMainLink = 'https://businesshelp.airthings.com/en/articles';
export const registrationPath = '/registration';
export const publicHomeReportPath = '/home-report';
export const unsubscribeFromAlertPath = '/unsubscribe/integrations';
export const spinnerPath = '/spinner';
export const appDevicePagePath = '/app-devices/:device';

export const mixpanelProdToken = '28859c2c8000c09993b00cf406ae4d2f';
export const mixpanelDevToken = '130e0ac084b6ff397d18cd256c6cea5e';

export const ContentTypes = {
    JSON: 'application/json',
    CSV: 'text/csv',
    PDF: 'application/pdf',
};
export const navigationBreakWidth = {
    hamburger: 545,
    slim: 1500,
};

export const airthingsGeoLocation = {
    lat: 59.918899,
    lng: 10.7296083,
};

export const paths = {
    buildings: 'buildings',
    devices: 'devices',
    hub: 'hub',
    devicePage: 'devices/:serialNumber',
    changeLocation: 'devices/:device?/change-location',
    radonReport: 'devices/:serialNumber?/radon-report',
    iaqReport: 'devices/:serialNumber?/iaq-report',
    pastSegment: 'devices/:device?/:segment?',
    pastSegmentRadonReport: 'devices/:serialNumber?/:segmentId?/radon-report',
    pastSegmentIqaReport: 'devices/:serialNumber?/:segmentId?/iaq-report',
    settings: 'settings',
    addDevice: 'add-devices',
    addTile: 'add-tile',
    addTileOfType: 'add-tile/:type',
    outdoor: 'outdoor/:location',
    somethingWentWrong: '404',
    notifications: 'settings/notifications',
    security: 'settings/security',
    partner: 'partner',
    // api:
    addApiClient: 'integrations/api-integration/add-api-client',
    editApiClient: 'integrations/api-integration/:clientId',
    apiIntegration: 'integrations/api-integration',
    integrations: 'integrations',
};

export const businessPaths = {
    ...paths,
    addAlert: 'alerts/add-alert',
    addBuilding: 'buildings/add-building',
    addDashboard: 'add-dashboard',
    addDevice: 'add-devices',
    addTile: 'add-tile',
    addTileOfType: 'add-tile/:type',
    addWebhook: 'integrations/webhooks/add-webhook',
    addMqttClient: 'integrations/mqtt/add-mqtt-client',
    alerts: 'alerts',
    history: 'alerts/history',
    alertEventPage: 'alerts/history/:historyId',
    editAlert: 'alerts/alert/:alertId',
    editWebhook: 'integrations/webhooks/:webhookId',
    editMqttClient: 'integrations/mqtt/:clientId',
    buildingPage: 'buildings/:buildingId',
    buildingDevices: 'devices',
    buildingControlDevices: 'control',
    buildingSettings: 'settings',
    buildingAddGroup: 'buildings/:buildingId/add-group',
    buildingAddDevice: 'add-device',
    buildingStatus: 'status',
    buildings: 'buildings',
    changeLocation: 'devices/:device/change-location',
    calibrationCertificate: 'reports/calibration-certificate',
    dashboard: '',
    dashboardSettings: 'dashboard-settings',
    devices: 'devices',
    emailSummary: 'buildings/email-summary',
    emailSummaryReport: 'reports/email-summary',
    hubPage: 'hub/:hubId',
    iaqInsight: 'reports/iaq',
    iaqCertificate: 'reports/iaq-certificate',
    iaqReport: 'devices/:serialNumber/iaq-report',
    iaqInsightEditDevice: 'reports/iaq/edit-device/:device',
    iaqCertificateEditDevice: 'reports/iaq-certificate/edit-device/:device',
    reports: 'reports',
    organization: 'settings/organization',
    outdoor: 'outdoor/:location',
    outdoorInsightPage: 'reports/outdoor',
    outdoorInsightEditDevice: 'reports/outdoor/edit-device/:device',
    partnerAddCustomer: 'partner/add-customer',
    partnerCustomerPage: 'partner/:childUserGroupId',
    partnerCustomerSubscriptions: 'partner/:childUserGroupId/subscriptions',
    partnerPage: 'partner',
    partnerStatus: 'partner/status',
    partnerSummary: 'partner/summary',
    pastSegment: 'devices/:device/:segment',
    pastSegmentRadonReport: 'devices/:serialNumber/:segmentId/radon-report',
    pastSegmentIqaReport: 'devices/:serialNumber/:segmentId/iaq-report',
    pressureInsight: 'reports/pressure',
    pressureInsightEditDevice: 'reports/pressure/edit-device/:device',
    energySavingsInsight: 'reports/energy-savings',
    energySavingsInsightEditDevice: 'reports/energy-savings/edit-device/:device',
    radonInsight: 'reports/radon',
    radonInsightEditDevice: 'reports/radon/edit-device/:device',
    radonReport: 'devices/:serialNumber/radon-report',
    settings: 'settings',
    spaceFloorPlan: 'buildings/:buildingId/spaces/floor-plan',
    analytics: 'analytics',
    status: 'status',
    somethingWentWrong: '404',
    spaces: 'buildings/:buildingId/spaces',
    spacePage: 'buildings/:buildingId/spaces/:spaceId',
    subscription: 'settings/subscription',
    startSubscription: 'partner/:childUserGroupId/start-subscription',
    startSubscriptionDirectCustomer: 'settings/subscription/start-subscription',
    thresholdBreachReport: 'buildings/:buildingId/insights/threshold-breach',
    userManagement: 'settings/user',
    virusRiskInsight: 'reports/virus-risk',
    virusRiskInsightEditDevice: 'reports/virus-risk/edit-device/:device',
    webhooks: 'integrations/webhooks',
    thirdParty: 'integrations/third-party',
    addThirdPartyIntegration: 'integrations/third-party/add-third-party-integration',
    editThirdPartyIntegrationMapping: 'integrations/third-party/:integrationId',
    mqtt: 'integrations/mqtt',
};

export const roleRestrictions = {
    building: RequiredRoleLevel.ANY_ROLE,
    editBuilding: RequiredRoleLevel.ABOVE_EDITOR,
    webhooks: RequiredRoleLevel.ABOVE_EDITOR,
    api: RequiredRoleLevel.ABOVE_EDITOR,
    thirdPartyIntegration: RequiredRoleLevel.ABOVE_ADMIN,
    alerts: RequiredRoleLevel.ABOVE_EDITOR,
    reports: RequiredRoleLevel.ABOVE_EDITOR,
    buildingsStatus: RequiredRoleLevel.ANY_ROLE,
    partnerAddCustomer: RequiredRoleLevel.ABOVE_ADMIN,
    editPublicDashboard: RequiredRoleLevel.ABOVE_ADMIN,
    partner: RequiredRoleLevel.ABOVE_ADMIN,
    editDeviceOrBuilding: RequiredRoleLevel.ABOVE_EDITOR,
    createEditEmailSummary: RequiredRoleLevel.ABOVE_ADMIN,
    subscriptions: RequiredRoleLevel.ABOVE_ADMIN,
    usersAndOrgManagement: RequiredRoleLevel.ABOVE_ADMIN,
    dashboardTiles: RequiredRoleLevel.ANY_ROLE,
    mqtt: RequiredRoleLevel.ABOVE_EDITOR,
    buildingHealth: RequiredRoleLevel.ABOVE_ADMIN,
    space: RequiredRoleLevel.ANY_ROLE,
    editSpace: RequiredRoleLevel.ABOVE_EDITOR,
    editThresholds: RequiredRoleLevel.ABOVE_ADMIN,
};

export const languages: { [key: string]: string } = {
    en: 'English',
    fr: 'Français',
    de: 'Deutsch',
    nb: 'Norsk',
    da: 'Dansk',
};

export const norwegianFallbackLanguageName = 'Norsk bokmål (default)';
export const englishFallbackLanguageName = 'English US (fallback)';

export const sensorFullNameKey = (sensor: string): string => (sensor === SensorTypes.temp ? 'Temperature' : sensor);

export const deviceTypes: { [key: string]: DeviceType } = {
    hub: new DeviceType({
        type: DeviceTypeNames.hub,
        prefix: '28',
        validPrefixes: ['2820', '2810'],
    }),
    wavePlus: new DeviceType({
        type: DeviceTypeNames.wavePlus,
        prefix: '2930',
        validPrefixes: ['2930'],
        sensors: [
            SensorTypes.radonShortTermAvg,
            SensorTypes.voc,
            SensorTypes.co2,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.pressure,
            SensorTypes.light,
            SensorTypes.virusRisk,
        ],
    }),
    [DeviceTypeNames.spaceCo2Mini]: new DeviceType({
        type: DeviceTypeNames.spaceCo2Mini,
        prefix: '31',
        validPrefixes: ['311', '312'],
        sensors: [
            SensorTypes.co2,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.sla,
            SensorTypes.lux,
            SensorTypes.pressure,
        ],
    }),
    waveCo2: new DeviceType({
        type: DeviceTypeNames.waveCo2,
        prefix: '293',
        validPrefixes: ['293'],
        sensors: [SensorTypes.co2, SensorTypes.humidity, SensorTypes.temp, SensorTypes.virusRisk],
    }),
    wave2: new DeviceType({
        type: DeviceTypeNames.wave2,
        prefix: '2950',
        validPrefixes: ['2950'],
        sensors: [SensorTypes.radonShortTermAvg, SensorTypes.humidity, SensorTypes.temp, SensorTypes.light],
    }),
    waveMini: new DeviceType({
        type: DeviceTypeNames.mini,
        prefix: '2920',
        validPrefixes: ['2920'],
        sensors: [
            SensorTypes.voc,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.pressure,
            SensorTypes.light,
            SensorTypes.mold,
        ],
    }),
    waveMist: new DeviceType({
        type: DeviceTypeNames.mist,
        prefix: '2940',
        validPrefixes: ['2940'],
        sensors: [SensorTypes.humidity, SensorTypes.temp, SensorTypes.light],
    }),
    wave: new DeviceType({
        type: DeviceTypeNames.wave,
        prefix: '2900',
        validPrefixes: ['2900'],
        sensors: [SensorTypes.radonShortTermAvg, SensorTypes.humidity, SensorTypes.temp, SensorTypes.light],
    }),
    viewPlus: new DeviceType({
        type: DeviceTypeNames.viewPlus,
        prefix: '296',
        validPrefixes: ['2960'],
        sensors: [
            SensorTypes.radonShortTermAvg,
            SensorTypes.pm25,
            SensorTypes.co2,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.voc,
            SensorTypes.pressure,
            SensorTypes.pm1,
            SensorTypes.sla,
            SensorTypes.noise,
            SensorTypes.light,
        ],
    }),
    viewPlusBusiness: new DeviceType({
        type: DeviceTypeNames.viewPlusBusiness,
        prefix: '296',
        validPrefixes: ['2969', '2966', '2967'],
        sensors: [
            SensorTypes.pm25,
            SensorTypes.co2,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.voc,
            SensorTypes.pressure,
            SensorTypes.pm1,
            SensorTypes.pm10,
            SensorTypes.sla,
            SensorTypes.noise,
            SensorTypes.light,
        ],
    }),
    viewCo2: new DeviceType({
        type: DeviceTypeNames.viewCo2,
        prefix: '296',
        validPrefixes: ['2969', '2968'],
        sensors: [
            SensorTypes.temp,
            SensorTypes.humidity,
            SensorTypes.co2,
            SensorTypes.light,
            SensorTypes.sla,
            SensorTypes.noise,
        ],
    }),
    [VirtualDeviceType.aggregatedGroup]: new DeviceType({
        type: VirtualDeviceType.aggregatedGroup,
        prefix: 'V001',
        validPrefixes: ['V001'],
        sensors: [SensorTypes.humidity, SensorTypes.temp, SensorTypes.pressure],
    }),
    [VirtualDeviceType.inletAirControl]: new DeviceType({
        type: VirtualDeviceType.inletAirControl,
        prefix: 'V004',
        validPrefixes: ['V004'],
        sensors: [SensorTypes.controlSignal, SensorTypes.regulationPressure, SensorTypes.regulationHeight],
    }),
    home: new DeviceType({
        type: DeviceTypeNames.home,
        prefix: '2',
        validPrefixes: ['2302', '2402', '2403'],
    }),
    [DeviceTypeNames.viewPollution]: new DeviceType({
        type: DeviceTypeNames.viewPollution,
        prefix: '2980',
        validPrefixes: ['2980'],
        sensors: [SensorTypes.pm25, SensorTypes.humidity, SensorTypes.temp],
    }),
    [DeviceTypeNames.viewRadon]: new DeviceType({
        type: DeviceTypeNames.viewRadon,
        prefix: '2989',
        validPrefixes: ['2989'],
        sensors: [SensorTypes.radonShortTermAvg, SensorTypes.humidity, SensorTypes.temp],
    }),
    [DeviceTypeNames.bacnet]: new DeviceType({
        type: DeviceTypeNames.bacnet,
        prefix: 'BAC',
        validPrefixes: ['BAC'],
        sensors: [SensorTypes.co2, SensorTypes.temp, SensorTypes.humidity],
    }),
    [DeviceTypeNames.waveNano]: new DeviceType({
        type: DeviceTypeNames.waveNano,
        prefix: '31',
        validPrefixes: ['313', '314'],
        sensors: [SensorTypes.temp, SensorTypes.humidity, SensorTypes.pressure, SensorTypes.lux],
    }),
    [DeviceTypeNames.ravenRadon]: new DeviceType({
        type: DeviceTypeNames.ravenRadon,
        prefix: '325',
        validPrefixes: ['325'],
        sensors: [SensorTypes.radonShortTermAvg, SensorTypes.temp, SensorTypes.humidity],
    }),
    [DeviceTypeNames.waveEnhance]: new DeviceType({
        type: DeviceTypeNames.waveEnhance,
        prefix: '32',
        validPrefixes: ['321', '322'],
        sensors: [
            SensorTypes.co2,
            SensorTypes.voc,
            SensorTypes.humidity,
            SensorTypes.temp,
            SensorTypes.pressure,
            SensorTypes.sla,
            SensorTypes.lux,
        ],
    }),
    [DeviceTypeNames.ap1]: new DeviceType({
        type: DeviceTypeNames.ap1,
        prefix: '410',
        validPrefixes: ['410'],
        sensors: [SensorTypes.pm25],
    }),
};

export type DeviceModel = keyof typeof deviceTypes;

export const sensorOrder: SensorTypes[] = Object.keys(SensorTypes) as SensorTypes[];

export const sensorGraphHeights = {
    deviceViewMinimizedWithLegend: 120,
    deviceViewMinimized: 110,
    deviceViewExpanded: 207,
    dashboardView: 168,
    mobileView: 175,
};

export const batteryLevels = {
    low: 15,
    good: 60,
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyA-Gu86_LIDM4P5mvYD_ZbOz--SOb3UY3s';

export const graphResolutions = {
    full: {
        resolution: undefined,
        resolutionDuration: moment.duration(1, 'hours'),
    },
    hour: {
        resolution: Resolution.hour,
        resolutionDuration: moment.duration(1, 'hours'),
    },
    fourHours: {
        resolution: Resolution.fourHours,
        resolutionDuration: moment.duration(4, 'hours'),
    },
    day: {
        resolution: Resolution.day,
        resolutionDuration: moment.duration(1, 'days'),
    },
    threeDays: {
        resolution: Resolution.threeDays,
        resolutionDuration: moment.duration(3, 'days'),
    },
};

export const customPeriodName = 'custom';

export const sensorGraphPeriods = {
    halfDay: {
        name: '12hours',
        number: 12,
        period: 'hours',
        ...graphResolutions.full,
    },
    twoDays: {
        name: '48hours',
        number: 2,
        period: 'days',
        ...graphResolutions.full,
    },
    week: {
        name: 'week',
        number: 7,
        period: 'days',
        ...graphResolutions.hour,
    },
    month: {
        name: 'month',
        number: 1,
        period: 'months',
        ...graphResolutions.fourHours,
    },
    year: {
        name: 'year',
        number: 1,
        period: 'years',
        ...graphResolutions.threeDays,
    },
};

export const customSensorGraphPeriods = {
    threeDaysResolution: moment.duration(8, 'months').asSeconds(), // 8 months +
    dayResolution: moment.duration(2, 'months').asSeconds(), // 2 month - 8 months
    fourHoursResolution: moment.duration(2, 'weeks').asSeconds(), // 2 weeks - 2 month
    hourResolution: moment.duration(48, 'hours').asSeconds(), // 48h - 2 weeks
};

export const measurementUnits = {
    ft: {
        type: 'feet',
        key: 'ft',
        length: 'ft',
        area: 'ft2',
        volume: 'ft3',
        cm: 'in',
    },
    m: {
        type: 'meter',
        key: 'm',
        length: 'm',
        area: 'm2',
        volume: 'm3',
        cm: 'cm',
    },
};

export const dateFormats = {
    US: {
        type: 'US',
        format: 'MM.DD.YYYY',
        shortFormat: 'MM/DD/YY',
        downloadFormat: 'MM-DD-YY',
        calendarFormat: 'MM/DD/YYYY',
        timeOfDay: 'hh A',
        hourFormat: 'LT',
        date: '%m.%d.%Y',
        time: '%I:%M %p',
        hourTime: '%I %p',
        weekDay: '%A',
        momentWeek: 'MM.DD',
        axisDate: {
            '12hours': '%I:%M %p',
            '48hours': '%I:%M %p',
            week: '%m.%d',
            month: '%m.%d',
            year: '%m.%d',
        },
        axisDateWithDay: '%a, %m.%d',
        alertDateTime: 'ddd MMM D',
    },
    EUR: {
        type: 'EUR',
        format: 'DD.MM.YYYY',
        shortFormat: 'DD/MM/YY',
        downloadFormat: 'DD-MM-YY',
        calendarFormat: 'DD/MM/YYYY',
        timeOfDay: 'HH:mm',
        hourFormat: 'HH:mm',
        date: '%d.%m.%Y',
        time: '%H:%M',
        hourTime: '%H:00',
        weekDay: '%A',
        momentWeek: 'DD.MM',
        axisDate: {
            '12hours': '%H:%M',
            '48hours': '%H:%M',
            week: '%d.%m',
            month: '%d.%m',
            year: '%d.%m',
        },
        axisDateWithDay: '%a, %d.%m',
        alertDateTime: 'ddd D MMM',
    },
};

export const sensorUnits: { [unit: string]: string } = {
    ach: 'ACH',
    bq: 'Bq/m\u00B3',
    c: '\u00B0',
    f: '\u00B0',
    pa: 'Pa',
    pct: '%',
    pci: 'pCi/L',
    ppb: 'ppb',
    ppm: 'ppm',
    mbar: 'mBar',
    'ug/m3': '\u00B5g/m\u00B3',
    mgpc: '\u00B5g/m\u00B3',
    pcpp1l: '\u00B5g/m\u00B3',
    dbspl: 'dBA',
    dba: 'dBA',
    riskIndex: '/10',
    m3h: 'm\u00B3/h',
    binary: '',
    occ: '',
    none: '',
    info: '',
    kWh: 'kW/h',
    controlSignal: '/10',
    m: 'm',
    hpa: 'hPa',
    inhg: 'inHg',
    lx: 'lux',
    dbm: 'dBm',
    db: 'dB',
    minutes: 'min',
};

export const colors = {
    yellowGlow: cssColors.yellowGlow,
    yellowSunlight: cssColors.yellowSunlight,
    yellowCaramel: cssColors.yellowCaramel,
    yellowSepia: cssColors.yellowSepia,
    redAuburn: cssColors.redAuburn,
    redPersian: cssColors.redPersian,
    redBurgundy: cssColors.redBurgundy,
    greenSpring: cssColors.greenSpring,
    greenRacing: cssColors.greenRacing,
    greenForest: cssColors.greenForest,
    blueAura: cssColors.blueAura,
    blueCelestial: cssColors.blueCelestial,
    blueCerulean: cssColors.blueCerulean,
    blueAzul: cssColors.blueAzul,
    blueBondi: cssColors.blueBondi,
    blueVenice: cssColors.blueVenice,
    yellowHotButter: cssColors.yellowHotButter,
    greenJade: cssColors.greenJade,
    greenBiosphere: cssColors.greenBiosphere,
    greyMist: cssColors.greyMist,
    greyNepal: cssColors.greyNepal,
    osloGray: cssColors.osloGray,
    greyRiverBed: cssColors.greyRiverBed,
    blackShark: cssColors.blackShark,
    greyConcrete: cssColors.greyConcrete,
    greyAsh: cssColors.greyAsh,
    greyShuttle: cssColors.greyShuttle,
    greyPorcelain: '#F1F3F4',
    defaultGraphColor: '#3CA9F6', // blue-aura
    graphYellow: '#FFC000', // yellow-sunlight
    graphBlue: '#61BAFF', // blue-bondi
    graphRed: '#D32E2F', // red-persian
    graphGreen: '#4BC682', // green-biosphere
    white: '#FFFFFF',
};

export const tooltipBorderColor = 'rgba(57, 69, 77, 0.25)';

export const sensorGraphColors: { [sensor: string]: string } = graphColorSensors.reduce((graphColors, sensor) => {
    const sensorsWithColors = (): { [p: string]: string } => {
        switch (sensor) {
            case SensorTypes.temp: {
                return { [`0${sensor}`]: colors.graphBlue, [`1${sensor}`]: colors.graphGreen };
            }
            case SensorTypes.humidity: {
                return {
                    [`0${sensor}`]: colors.graphRed,
                    [`1${sensor}`]: colors.graphYellow,
                    [`2${sensor}`]: colors.graphGreen,
                    [`3${sensor}`]: colors.graphYellow,
                };
            }
            default:
                return { [`0${sensor}`]: colors.graphGreen, [`1${sensor}`]: colors.graphYellow };
        }
    };
    return { ...graphColors, ...sensorsWithColors() };
}, {});

export const availableSensorOptions = Object.keys(SensorTypes).map(sensor => ({
    type: sensor,
    name: sensorFullNameKey(sensor),
}));

export const organizationInviteResponses = {
    accept: 'ACCEPTED',
    deny: 'DECLINED',
};

export const rssiThresholds = {
    fourBars: -80,
    threeBars: -85,
    twoBars: -90,
    oneBars: -95,
};

export const mapMoldLinkToLanguage: { [key: string]: string } = {
    en: 'https://www.airthings.com/en/what-is-mold',
    no: 'https://www.airthings.com/no/hva-er-mugg',
    de: 'https://www.airthings.com/de/was-ist-schimmel',
    fr: 'https://www.airthings.com/fr/qu-est-ce-que-la-moisissure',
    da: 'https://www.airthings.com/da/what-is-mould',
};

export const validDashboardTiles = ['sensor', 'device', 'location', 'outdoor', 'in_vs_out', 'sensorValue'];

export const LocalStorageKeys = {
    SelectedGroup: 'selectedGroup',
    language: 'i18nextLng',
    residentialFeatureToggle: 'residential',
};

export type RelayDevice = 'app' | DeviceTypeNames.hub;

export const Consumer = 'Consumer';

export const dayIndex = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
};

export const mobileMax = 890;

export enum Environment {
    dev = 'dev',
    prod = 'prod',
}

export const ThresholdSlidercolorMap = {
    POOR: colors.graphRed,
    GOOD: colors.graphGreen,
    FAIR: colors.graphYellow,
};
